
import { settingsModule } from "@/store";
import { mixins, Options } from "vue-class-component";
import { copy, elementReady, titleCase, stringFirstChar } from "@/utils";
import { HandlersMixin, InteractMixin, MarkersMixin } from "@/mixins";
import {
  SettingsInterface,
  SettingsItem,
  PropertiesInterface,
  VisualisationsInterface,
} from "@/types";
import { useToast } from "balm-ui";
import SvgView from "@/components/ui/svgView/svgView.vue";
import SvgIcon from "@/components/ui/icons/SvgIcon.vue";
import SimpleFileUpload from "@/components/fileUpload/Simple.vue";
import config from "@/config/config";

const $toast = useToast();

@Options({
  name: "Visualisations",
  components: { SvgView, SvgIcon, SimpleFileUpload },
  watch: {
    activeOptions: {
      handler(val) {
        this.setActiveProperties(val.part.name as keyof PropertiesInterface);

        this.checkedProperty = "";
        this.setActiveVisualisation(val);
      },
      deep: true,
    },
    checkedProperty(val) {
      if (this.activeMarker.length) {
        const [marker] = this.activeMarker;

        for (const key in marker) {
          for (const positions of marker[key]) {
            this.setMarker(positions);
          }
        }
      } else {
        this.removeMarkerGroup();
      }
    },
    editState(val) {
      if (val) {
        this.setInteract();
      } else {
        this.destroyInteract();
      }
    },
    "activeVisualisation.src": {
      handler(val: any) {
        if (val && this.editState) {
          this.setInteract();
        } else {
          this.destroyInteract();
        }
      },
      deep: true,
    },
    values: {
      handler(value: { w: number; h: number; x: number; y: number }) {
        console.log(value);
      },
      deep: true,
    },
  },
})
export default class Visualisations extends mixins(
  InteractMixin,
  HandlersMixin,
  MarkersMixin
) {
  private menuOpen = { gender: false, part: false, type: false, add: false };
  private fullscreen = false;
  private isImageUploaded = true;
  private toastTimeOut = 2000;
  private activeProperties: Array<{ [key: string]: any }> = [];
  private activeOptions: { [key: string]: any } = {};
  private checkedProperty = "";
  private toolsItems = [
    {
      name: "gender",
      src: ["male-ico.svg", "female-ico.svg"],
      items: [],
    },
    {
      name: "part",
      src: ["figure-ico.svg", "head-ico.svg", "hand-ico.svg", "foot-ico.svg"],
      items: [],
    },
    {
      name: "type",
      src: "type-ico.svg",
      items: [],
    },
  ];
  protected vData: VisualisationsInterface = {
    figure: {},
    head: {},
    hand: {},
    foot: {},
  };
  protected aData: { [key: string]: any } = {};
  protected editState = false;

  private get values(): any {
    return InteractMixin.values;
  }

  private get getSettings(): SettingsInterface {
    return settingsModule.getSettings;
  }

  private get getProperties(): PropertiesInterface {
    return this.getSettings.properties;
  }

  private get visualisationsData(): VisualisationsInterface {
    return this.vData;
  }

  private set visualisationsData(visualisations: VisualisationsInterface) {
    this.vData = visualisations;
  }

  private get activeVisualisation(): { [key: string]: any } {
    return this.aData;
  }

  private set activeVisualisation(aData: { [key: string]: any }) {
    this.aData = aData;
  }

  private get getPropertyId(): string {
    const [, , propertyId] = this.checkedProperty.split("_");
    return propertyId;
  }

  private get activeMarker(): any {
    const isSavedVisualisation =
      Object.keys(this.activeVisualisation).length > 1;
    if (!this.checkedProperty || !isSavedVisualisation) return [];

    return this.activeVisualisation.markers.filter(
      (m: any) => m[`markers-${this.getPropertyId}`]
    );
  }

  private get getPayloadData(): any {
    const markers = this.activeVisualisation.markers;
    const markerKey = `markers-${this.getPropertyId}`;
    const isValidActiveMarker = this.activeMarker.length > 0;
    const markerPositions = this.getMarkerPositions();
    const markerIndex = this.activeVisualisation.markers.findIndex(
      (m: any) => m[markerKey]
    );
    let payloadData = {};

    if (!isValidActiveMarker && markerPositions.length) {
      markers.push({ [markerKey]: markerPositions });
    }

    if (isValidActiveMarker && markerIndex > -1) {
      markers[markerIndex][markerKey] = markerPositions;
    }

    payloadData = {
      part_id: this.activeOptions.part.id,
      type_id: this.activeOptions.type.id,
      gender_id: this.activeOptions.gender.id,
      src: this.activeVisualisation.src,
      transformation:
        document.getElementById("svgHolder")!.getAttribute("style") || "",
      markers: markers,
    };

    if (this.activeVisualisation.add) {
      payloadData = { ...this.getActiveOptionsKeys, ...payloadData };
    }

    delete this.activeVisualisation.add;

    return payloadData;
  }

  private get getActiveOptionsKeys(): { [key: string]: string } {
    if (!Object.keys(this.activeOptions).length) return {};

    return {
      part_key: this.activeOptions.part.name,
      type_key: this.activeOptions.type.name,
      gender_key: this.activeOptions.gender.name,
    };
  }

  private getIconPath(src: any, name: string): string {
    if (Array.isArray(src)) {
      if (!Object.keys(this.activeOptions).length) return src[0];

      const key = this.activeOptions[name].name;
      return src[src.findIndex((s: string) => s.indexOf(key) > -1)];
    }

    return src;
  }

  private setActiveOptions(key: string, item: { [key: string]: any }): void {
    this.activeOptions[key] = item;
  }

  private setActiveProperties(key: keyof PropertiesInterface): void {
    let properties = this.getProperties[key];

    if (key === 'head') {
      const shortList = ['newborn', 'infant', 'young_child', 'child'];
      const type = this.activeOptions.type.name as string;
      const excludeProperty = shortList.includes(type) ?
          ['length of eye and space between eyes'] :
          ['length of eye', 'space between eyes'];

      properties = properties?.filter((p: any) => !excludeProperty.includes(p.title));
    }

    this.activeProperties = properties.map((i: any) => ({ text: i.title, value: `properties_${key}_${i.id}` }));
  }

  private setMenuItems(): void {
    const settings = this.getSettings;

    for (const menu of this.toolsItems) {
      const items: SettingsItem[] | PropertiesInterface =
        settings[menu.name as keyof SettingsInterface];

      if (items) {
        menu.items = copy(items).map((i: any) => ({
          text: i.title,
          value: `${menu.name}_${i.id}`,
        }));

        this.setActiveOptions(menu.name, copy(items)[0]);
      }
    }
  }

  private setInteract(): void {
    elementReady(`.svg-holder`).then((holder: any) => {
      this.onInteract(`#${holder.id}`);
      this.setHandlers(holder);
      this.setMarkerInteract();
    });
  }

  private setActiveVisualisation(actives: { [key: string]: any }): any {
    const gender = actives.gender.name;
    const part = actives.part.name;
    const type = actives.type.name;
    const data = this.visualisationsData[part as keyof VisualisationsInterface];

    if (data && data![type] && data![type][gender]) {
      this.activeVisualisation = data![type][gender];
    } else {
      this.activeVisualisation = {
        src: "",
        markers: [],
        transformation: "",
        add: true,
      };
    }
  }

  private destroyInteract(): void {
    elementReady(`.svg-holder`).then((holder: any) => {
      this.onDestroy(`#${holder.id}`, !!this.activeVisualisation.src);
      this.removeHandlers(holder);
      this.removeMarkerInteract();
    });
  }

  private addMarker(): void {
    this.setMarker(copy(config.markers));
    this.setMarkerInteract();
  }

  private addVisualisation(): void {
    settingsModule.Add({
      payload: this.getPayloadData,
      callback: (res?: any) => {
        if (!res.error) {
          this.activeVisualisation = res;
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private editVisualisation(): void {
    settingsModule.Edit({
      id: this.activeVisualisation.id,
      payload: {
        ...this.activeVisualisation,
        ...this.getPayloadData,
      },
      callback: (res: any) => {
        if (!res.error) {
          this.activeVisualisation = res;
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private titleCase(str: string): string {
    return titleCase(str);
  }

  private stringFirstChar(str: string): string {
    return stringFirstChar(str);
  }

  private onFileUpload(file: any): void {
    if (typeof file.uploaded === "boolean" && file.uploaded) {
      this.isImageUploaded = file.uploaded;

      this.activeVisualisation = {
        ...this.activeVisualisation,
        ...{ src: file.src },
      };
    }
  }

  private onFileRemove(): void {
    this.isImageUploaded = false;
    this.activeVisualisation = { ...this.activeVisualisation, ...{ src: "" } };
  }

  private onSelected(data: { [key: string]: any }): void {
    const [key, id] = data.value.split("_");
    const activeOption = copy(this.getSettings[key as keyof SettingsInterface]);
    const [active] = activeOption.filter(
      (a: { [key: string]: any }) => a.id === id
    );

    this.setActiveOptions(key, active);
  }

  private onCancelAction(): void {
    this.destroyInteract();
    this.editState = false;
  }

  private onStoreAction(): void {
    if (this.editState) {
      this.activeVisualisation.add
        ? this.addVisualisation()
        : this.editVisualisation();
    }

    this.editState = !this.editState;
  }

  created(): void {
    settingsModule.LoadVisualisations();
    settingsModule.Load();
  }

  mounted(): void {
    settingsModule.$watch(
      (module) => module.getSettings,
      () => this.setMenuItems()
    );

    settingsModule.$watch(
      (module) => module.getVisualisations,
      () => {
        this.visualisationsData = settingsModule.getVisualisations;
      }
    );
  }
}
