
import { Options, Vue } from "vue-class-component";
import { copy, search } from "@/utils";
import { LanguagesInterface } from "@/types";
import { languagesModule } from "@/store";

@Options({
  name: "LanguageSelect",
  watch: {
    language(val) {
      if (val) languagesModule.setActiveLanguage(val);
    },
  },
  props: {
    direction: {
      type: String,
      default: "ltr",
    },
  },
})
export default class LanguageSelect extends Vue {
  private language = 1;

  private get getLanguages(): LanguagesInterface[] {
    return languagesModule.getLanguages;
  }

  private selectData(
    data: LanguagesInterface[]
  ): Array<{ [key: string]: string }> {
    if (!data) return [];
    let selectData: { [key: string]: any }[] = [];

    for (const asset of data) {
      selectData.push({
        label: asset.name,
        value: asset.id,
      });
    }

    return selectData;
  }

  created(): void {
    languagesModule.Load();
  }
}
