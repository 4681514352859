
import { mixins, Options } from "vue-class-component";
import { useToast } from "balm-ui";
import { SettingsInterface, SettingsItem, SettingsTabs } from "@/types";
import { SortableMixin } from "@/mixins";
import { settingsModule } from "@/store";
import { copy, lengthOf } from "@/utils";
import ZeroState from "@/components/ui/zeroState/index.vue";
import Visualisations from "@/components/tabs/Visualisations.vue";

@Options({
  name: "Settings",
  components: { ZeroState, Visualisations },
  watch: {
    activeTab(val) {
      this.selectedRows = [[], [], [], [], []];

      settingsModule.ActiveTab(val);
    },
  },
})
export default class Settings extends mixins(SortableMixin) {
  private thead: any = [
    {
      value: "Id",
      sort: "asc",
      columnId: "id",
    },
    "Name",
    "Actions",
  ];
  private tbody: any = [
    {
      field: "id",
      width: 80,
      fixed: "left",
    },
    "title",
    {
      width: 100,
      slot: "actions",
    },
  ];
  private selectedRows: Array<Array<number>> = [[], [], [], [], []];
  private tData: SettingsInterface = {
    gender: [],
    part: [],
    type: [],
    properties: {
      figure: [],
      head: [],
      hand: [],
      foot: [],
    },
    unit: [],
  };
  private activeRowData!: SettingsItem | null;
  private actionType = "Create";
  private propertyType = "";
  private page = 1;
  private open = false;
  private aTab = 0;
  protected readonly toastTimeOut = 2000;

  private get getSettings(): SettingsInterface {
    return copy(settingsModule.getSettings);
  }

  private get isSubRoute(): boolean {
    const subRoutes = ["Create", "Edit"];

    return this.$route.matched.some(({ name }: { name: any }) =>
        subRoutes.some((sRoute: string) => name.indexOf(sRoute) > -1)
    );
  }

  private get getTabsLength(): number {
    return Object.keys(SettingsTabs).length / 2;
  }

  private get tableData(): SettingsInterface {
    return this.tData;
  }

  private set tableData(val: SettingsInterface) {
    this.tData = val;
  }

  private get activeTab(): number {
    return this.aTab;
  }

  private set activeTab(val: number) {
    this.aTab = val;
  }

  private getTabKey(i: number): keyof SettingsInterface {
    return SettingsTabs[i].toLowerCase() as keyof SettingsInterface;
  }

  private onConfirm(result: any): void {
    const typeToRedirect = ["Create", "Edit"];
    const $toast = useToast();
    const plainObject = copy(this.tableData);
    const keys = Object.keys(plainObject);

    if (result) {
      if (typeToRedirect.includes(this.actionType)) {
        let routerData = {
          name: `Settings/${this.actionType}/${keys[this.activeTab]}`,
          params: {},
        };

        if (this.activeRowData) {
          routerData = {
            ...routerData,
            ...{
              params: this.activeRowData,
            },
          };
        }

        routerData.params = {
          ...routerData.params,
          activeTab: this.activeTab,
          activeProperty: this.propertyType,
        };

        this.$router.push(routerData);
      } else {
        const title = this.activeRowData?.title;

        settingsModule.Delete({
          id: this.activeRowData?.id,
          callback: (error?: any) => {
            if (error) {
              $toast({ timeoutMs: this.toastTimeOut, message: error });
              return;
            }

            $toast(`${title} is deleted!`);
          }
        })
      }

      this.activeRowData = null;
    }
  }

  private editRow(data: any, key?: string): void {
    this.activeRowData = data;
    this.onModalOpen("Edit", key);
  }

  private deleteRow(data: any, key?: string): void {
    this.activeRowData = data;
    this.onModalOpen("Delete", key);
  }

  private onModalOpen(type: string, key?: string): void {
    this.open = !this.open;
    this.actionType = type;
    this.propertyType = key ? key : "";
  }

  private onDeleteSelectedRows(): void {
    /*
     *  todo: šis ir nepieciešams piesaistīt Apstiprinājuma logam, bet šo atstāt uz vēlāku laiku
     *  !! bet arī šeit ir jāvievēro princips, ka visi state izmaiņas notiek store @mutation daļā attiecīgajās metodēs
     * */

    const plainObject = copy(this.tableData);
    const keys = Object.keys(plainObject);

    //todo: move this to the @mutation part of the settings store module
    if (this.activeTab != SettingsTabs.Properties) {
      for (const row of copy(this.selectedRows[this.activeTab])) {
        plainObject[keys[this.activeTab]] = plainObject[
            keys[this.activeTab]
            ].filter((d: SettingsItem) => d.id !== row);
      }
      this.selectedRows[this.activeTab] = [];
    } else {
      const propertyKeys = Object.keys(
          plainObject[keys[SettingsTabs.Properties]]
      );
      for (let i = 0; i < propertyKeys.length; i++) {
        for (const row of copy(this.selectedRows[i])) {
          plainObject[keys[SettingsTabs.Properties]][propertyKeys[i]] =
              plainObject[keys[SettingsTabs.Properties]][propertyKeys[i]].filter(
                  (d: SettingsItem) => d.id !== row
              );
        }
        this.selectedRows[i] = [];
      }
    }

    // settingsModule.setSettings(plainObject);
  }

  private onPage(page: any): void {
    console.log(page);
  }

  private lengthOf2D(array: Array<Array<number>>) {
    return lengthOf(array);
  }

  private setUpListSorting(): void {
    const tableKeys = Object.keys(this.$refs);

    for (const key of tableKeys) {
      const table = this.$refs[key];

      this.onListSort(settingsModule, table)
    }
  }

  created(): void {
    settingsModule.Load();
  }

  mounted() {
    settingsModule.$watch(
        (module) => module.getSettings,
        () => {
          this.tableData = this.getSettings;

          this.$nextTick(() => this.setUpListSorting());
        }
    );
  }
}
