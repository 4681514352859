
import { Options, Vue } from "vue-class-component";
@Options({
  name: "AlertsError",
  props: {
    data: {
      type: Object,
    },
  },
})
export default class AlertsError extends Vue {}
