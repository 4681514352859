
import { Options, Vue } from "vue-class-component";
import { copy } from "@/utils";
import { mediaModule } from "@/store";
import { useToast } from "balm-ui";
import SvgIcon from "@/components/ui/icons/SvgIcon.vue";
import Image from "@/components/ui/image/Image.vue";
import SvgImage from "@/components/ui/image/SvgImage.vue";

const $toast = useToast();

@Options({
  name: "FileUpload",
  components: {
    SvgIcon,
    Image,
    SvgImage,
  },
  props: {
    data: {
      type: Object,
    },
    model: {
      type: String,
    },
    editState: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default() {
        return "image/*"
      }
    }
  },
  watch: {
    file: {
      handler(val){
        this.$emit("upload", val);
      },
      deep: true
    },
    isSetSource(val) {
      if (val) {
        this.file = { name: this.data.src, src: this.data.src };
      } else {
        this.file = {};
      }
    }
  },
})
export default class FileUpload extends Vue {
  private file: any = {};
  public data!: any;
  private model!: string;
  private accept!: string;

  private get isFile(): boolean {
    return Object.keys(this.file).length > 0;
  }

  private get isSetSource(): string {
    return this.data.src ? this.data.src : "";
  }

  private get isBlob(): boolean {
    if (!this.isFile) return false;
    return this.file.src.indexOf("blob:http") > -1;
  }

  private get getMedia(): any {
    return mediaModule.getMedia;
  }

  private get getUploadError(): any {
    return mediaModule.getErrors;
  }

  private onChange(files: any): void {
    const [file] = files;

    this.file = file;
    this.file.uploaded = false;
    this.file.src = URL.createObjectURL(file.sourceFile);
  }

  private setBg(src: string) {
    return src ? { backgroundImage: `url(${src})` } : {};
  }

  private async upload(file: any) {
    try {
      mediaModule.Upload(file.sourceFile);
    } catch (e) {
      console.error(e);
    }
  }

  private remove() {
    this.file = {};
    this.$emit("file-remove");

    if (!this.isBlob) {
      mediaModule.Remove({
        src: this.file.src,
        model: this.model,
        callback: () => {
          this.$emit("file-remove");
        },
      });
    }
  }

  mounted(): void {
    mediaModule.$watch(
        (module) => module.getMedia,
        () => {
          let file = copy(this.file);
          file.src = this.getMedia.filename;
          file.uploaded = true;

          this.file = file;
        }
    );

    mediaModule.$watch(
        (module) => module.getErrors,
        () => {
          if (this.file.name === this.data.src) {
            this.file = {}
            setTimeout(() => mediaModule.setErrors(""), 2000);
          }
        }
    );
  }

  destroyed() {
    mediaModule.setMedia("");
    mediaModule.setErrors("");
  }
}
