import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "svg-view" }
const _hoisted_2 = {
  key: 2,
  class: "actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgImage = _resolveComponent("SvgImage")!
  const _component_ui_fab = _resolveComponent("ui-fab")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ui_file = _resolveComponent("ui-file")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["svg-holder", { 'svg-holder-center': !_ctx.isFile, 'svg-holder-empty': !_ctx.isFile }]),
      style: _normalizeStyle(_ctx.data.transformation),
      id: "svgHolder"
    }, [
      (_ctx.isFile && !_ctx.isBlob)
        ? (_openBlock(), _createBlock(_component_SvgImage, {
            key: 0,
            id: "mySVG",
            global: true,
            src: _ctx.file?.src,
            alt: _ctx.file?.src
          }, null, 8, ["src", "alt"]))
        : _createCommentVNode("", true),
      (_ctx.isFile && _ctx.isBlob)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "preview",
            style: _normalizeStyle(_ctx.setBg(_ctx.file.src))
          }, null, 4))
        : _createCommentVNode("", true),
      (_ctx.editState && _ctx.isFile)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            (_ctx.isBlob && !_ctx.file.uploaded)
              ? (_openBlock(), _createBlock(_component_ui_fab, {
                  key: 0,
                  icon: "publish",
                  mini: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.upload(_ctx.file)))
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ui_fab, {
              icon: "delete",
              mini: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.remove()))
            })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isFile)
        ? (_openBlock(), _createBlock(_component_ui_file, {
            key: 3,
            preview: "",
            class: "upload-active__btn",
            accept: _ctx.accept,
            disabled: !_ctx.editState,
            name: "images",
            onChange: _ctx.onChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_svg_icon, {
                class: "icon icon--extra-large",
                src: `icons/upload.svg`
              }, null, 8, ["src"])
            ]),
            _: 1
          }, 8, ["accept", "disabled", "onChange"]))
        : _createCommentVNode("", true)
    ], 6)
  ]))
}