import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_alert = _resolveComponent("ui-alert")!
  const _component_ui_form_field = _resolveComponent("ui-form-field")!

  return (_openBlock(), _createBlock(_component_ui_form_field, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.$silentErrors, (error, i) => {
        return (_openBlock(), _createBlock(_component_ui_alert, {
          fullwidth: "",
          state: "error",
          key: i
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(error.$message), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}