
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import InlineSvg from "vue-inline-svg";

@Options({
  name: "SvgIcon",
  components: {
    InlineSvg,
  },
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String as PropType<string>,
    },
  },
})
export default class SvgIcon extends Vue {
  private src!: string;

  get getPath() {
    try {
      return require(`@/assets/images/${this.src}`);
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }
}
