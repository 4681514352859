import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ui_grid_cell = _resolveComponent("ui-grid-cell")!
  const _component_page_wrapper = _resolveComponent("page-wrapper")!

  return (_openBlock(), _createBlock(_component_page_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_ui_grid_cell, { columns: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}