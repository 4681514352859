
import { mixins, Options } from "vue-class-component";
import { Forms } from "@/mixins";
import { copy } from "@/utils";
import { useToast } from "balm-ui";
import { minLength, required } from "@vuelidate/validators";
import { SettingsInterface, SettingsItem, SettingsTabs } from "@/types";
import { languagesModule, menuModule, settingsModule } from "@/store";
import AlertsError from "@/components/forms/alerts/error/index.vue";
import LanguageSelect from "@/components/ui/selects/Language.vue";
import json = Mocha.reporters.json;

const $toast = useToast();
const allowedTabForKeyField = [SettingsTabs.Gender, SettingsTabs.Part, SettingsTabs.Type];

@Options({
  name: "SettingsForm",
  components: { AlertsError, LanguageSelect },
  watch: {
    selected(val) {
      if (val) {
        this.formData = { ...this.formData, ...{ part_id: val } };
      }
    }
  },
  validations() {
    if (!allowedTabForKeyField.includes(this.activeTab)) {
      this.formValidation.name = {};
    }

    return {
      formData: this.formValidation,
    }
  },
})
export default class SettingsForm extends mixins(Forms) {
  private itemId!: string;
  private activeTab = 0;
  private showKeyField = false;
  private form: SettingsItem = {
    title: "",
    name: "",
  };
  private formValidation = {
    title: {
      required,
      minLength: minLength(1),
    },
    name: {
      required
    }
  }

  private get formData(): SettingsItem {
    return this.form;
  }

  private set formData(data: SettingsItem) {
    this.form = data;
  }

  private get getSettings(): SettingsInterface {
    return settingsModule.getSettings;
  }

  private addSettings(): void {
    this.roastMessage = "Added!";

    if (!Object.values(this.formData).length) console.error("Missing data!");

    settingsModule.Add({
      payload: this.formData,
      callback: (res: any) => {
        if (!res.error) {
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });

          setTimeout(() => {
            this.isSaved = false;
            this.$router.push({ name: "Settings" });
          }, this.toastTimeOut);
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private editSettings(): void {
    this.roastMessage = "Saved!";

    if (!Object.values(this.formData).length) console.error("Missing data!");

    settingsModule.Edit({
      id: this.itemId,
      payload: this.formData,
      callback: (res: any) => {
        if (!res.error) {
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });

          setTimeout(() => {
            this.isSaved = false;
            this.$router.push({ name: "Settings" });
          }, this.toastTimeOut);
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private onSave(): void {
    this.isSaved = true;
    this.isEditSubRoute ? this.editSettings() : this.addSettings();
  }

  created(): void {
    const params = copy(this.$route.params);

    if (
        this.isEditSubRoute &&
        !Object.keys(params).length &&
        params.activeTab
    ) {
      this.$router.go(-1);
    } else {
      this.itemId = params.id;
      this.formData = { ...this.formData, ...params };
      this.activeTab = parseInt(params.activeTab);
    }
  }

  mounted(): void {
    this.showKeyField = false;

    if (allowedTabForKeyField.includes(this.activeTab)) {
      this.showKeyField = true;
    }

    languagesModule.$watch(
        (module) => module.getActiveLanguage,
        () => settingsModule.Load()
    );

    settingsModule.$watch(
        (module) => module.getSettings,
        () => {
          const key = SettingsTabs[this.activeTab].toLowerCase() as keyof SettingsInterface;
          const [setting] = (settingsModule.getSettings[key] as any).filter((f: any) => f.id === this.itemId)
          if (setting) {
            this.formData = { ...this.formData, ...setting };
          }
        }
    );
  }

  beforeUnmount(): void {
    languagesModule.setActiveLanguage("");
  }
}
