
import { Options, Vue } from "vue-class-component";
import SvgImage from "@/components/ui/image/SvgImage.vue";

@Options({
  name: "SvgView",
  components: {
    SvgImage,
  },
  props: {
    src: String,
    style: String
  },
})
export default class SvgView extends Vue {
  private style!: string;
}
