
import { Options, mixins } from "vue-class-component";
import { Forms } from "@/mixins";
import { copy } from "@/utils";
import { useToast } from "balm-ui";
import { minLength, required } from "@vuelidate/validators";
import { onlyAlpha } from "@/validators";
import { SettingsInterface, SettingsItem, SettingsPropertyItem, SettingsTabs } from "@/types";
import { settingsModule, languagesModule } from "@/store";
import AlertsError from "@/components/forms/alerts/error/index.vue";
import LanguageSelect from "@/components/ui/selects/Language.vue";

const $toast = useToast();

@Options({
  name: "SettingsForm",
  components: { AlertsError, LanguageSelect },
  watch: {
    selected(val) {
      if (val) {
        this.formData = { ...this.formData, ...{part_id: val} };
      }
    }
  },
  validations: {
    formData: {
      title: {
        required,
        minLength: minLength(1),
      },
    },
  },
})
export default class SettingsForm extends mixins(Forms) {
  private itemId!: string;
  private activeTab = 0;
  private activeProperty = ""; // izmantots tikai properties tab edit gadijuma
  private form: SettingsItem = {
    id: "",
    title: "",
  };

  private options: Array<{ [key: string]: any}> = [];
  private selected = "";

  private get formData(): SettingsItem {
    return this.form;
  }

  private set formData(data: SettingsItem) {
    this.form = data;
  }

  private get getSettings(): SettingsInterface {
    return settingsModule.getSettings;
  }

  private setActivePart(): void {
    const part = copy(this.formData).activeProperty;
    const [active] = this.getSettings.part.filter((p: SettingsItem) => p.name === part);

    this.selected = active
        ? active.id
        : this.options[0].value;
  }

  private setPartOptions(): void {
    if (this.getSettings) {
      this.options = this.getSettings.part.map((s: any) => ({ label: s.title, value: s.id }));
    }
  }

  private addSettings(): void {
    this.roastMessage = "Added!";

    if (!Object.values(this.formData).length) console.error("Missing data!");

    settingsModule.Add({
      payload: this.formData,
      callback: (res: any) => {
        if (!res.error) {
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });

          setTimeout(() => {
            this.isSaved = false;
            this.$router.push({ name: "Settings" });
          }, this.toastTimeOut);
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private editSettings(): void {
    this.roastMessage = "Saved!";

    if (!Object.values(this.formData).length) console.error("Missing data!");

    settingsModule.Edit({
      id: this.itemId,
      payload: this.formData,
      callback: (res: any) => {
        if (!res.error) {
          $toast({ timeoutMs: this.toastTimeOut, message: "Saved!" });

          setTimeout(() => {
            this.isSaved = false;
            this.$router.push({ name: "Settings" });
          }, this.toastTimeOut);
        } else {
          $toast({ timeoutMs: this.toastTimeOut, message: res.error });
        }
      },
    });
  }

  private onSave(): void {
    this.isSaved = true;
    this.isEditSubRoute ? this.editSettings() : this.addSettings();
  }

  created(): void {
    const params = copy(this.$route.params);

    if (
      this.isEditSubRoute &&
      !Object.keys(params).length &&
      params.activeTab
    ) {
      this.$router.go(-1);
    } else {
      this.itemId = params.id;
      this.formData = { ...this.formData, ...params };
      this.activeTab = parseInt(params.activeTab);
    }
  }

  mounted(): void {
    this.setPartOptions()
    this.setActivePart();

    languagesModule.$watch(
      (module) => module.getActiveLanguage,
      () => settingsModule.Load()
    );

    settingsModule.$watch(
      (module) => module.getSettings,
      () => {
        /**
         * todo: filter by id
         * this.formData = { ...this.formData, ...{}}
         */
      }
    );
  }
}
