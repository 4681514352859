import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("img", {
    src: _ctx.getPath,
    "aria-label": _ctx.alt,
    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args))),
    onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageLoadError && _ctx.onImageLoadError(...args)))
  }, null, 40, _hoisted_1)), [
    [_vShow, _ctx.isLoaded]
  ])
}