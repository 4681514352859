import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "svg",
  class: "svg-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgImage = _resolveComponent("SvgImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "svg-holder",
      id: "svgHolder",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createVNode(_component_SvgImage, {
        id: "mySVG",
        src: _ctx.src
      }, null, 8, ["src"])
    ], 4)
  ], 512))
}