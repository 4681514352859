
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";

@Options({
  name: "Image",
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String as PropType<string>,
    },
  },
})
export default class Image extends Vue {
  private src!: string;
  private isLoaded = false;
  private isError = false;

  get getPath(): string {
    if (this.isError) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }

    try {
      return require(`@/assets/images/${this.src}`);
    } catch (e) {
      return require(`@/assets/images/icons/broken-image.svg`);
    }
  }

  private onImageLoad(): void {
    this.isLoaded = true;
  }

  private onImageLoadError(): void {
    this.isError = true;
  }
}
